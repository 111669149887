
import { defineComponent, onMounted } from "vue";
import ClientClaimsTable from "@/components/individual/ClientClaimsTable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";

export default defineComponent({
  name: "broker-cooperative-claims-listing",
  components: { ClientClaimsTable },
  data() {
    return {
      claims: [],
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Claims", ["Broker", "Cooperative"]);
    });
  },
  created() {
    // Get client filed claims
    this.getClientsClaims();

    //Set page title
    document.title =
      "Broker: Cooperative Claims | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    getClientsClaims() {
      //Fetch client filed claims
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.BROKER_CREATE_COOPERATIVE_ROUTE}/claim`)
          .then(({ data }) => {
            //Assign data to props
            this.claims = data.data;
            this.displayDatatable();
          })
          .catch(function(error) {
            console.log(error);
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    displayDatatable() {
      setTimeout(() => {
        //Add jQuery datatable
        $("#basicExample").DataTable({
          iDisplayLength: 10,
          language: {
            searchPlaceholder: "Search...",
            sSearch: "",
            lengthMenu: "_MENU_ items/page",
            // "lengthMenu": "Display _MENU_ records per page",
            zeroRecords: "No matching records found",
            // "info": "Showing page _PAGE_ of _PAGES_",
            infoEmpty: "No records available",
            infoFiltered: "(filtered from _MAX_ total records)",
          },
          processing: true,
          destroy: true,
        });
      }, 3000);
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
  },
});
